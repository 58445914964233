<template>
    <div id="app">
        <!-- 顶部搜索 -->
        <div class="gl_top_view">
            <div class="gl_top_input">
                <div class="gl_top_fal">
                    <div class="title">团队名称</div>
                    <el-input class="input-with" v-model="pages.name" placeholder="请输入"></el-input>
                </div>
                <div class="gl_top_fal">
                    <div class="title">会员等级</div>
                    <el-select class="input-with input_comm" v-model="pages.levelId" placeholder="请选择">
                        <el-option v-for="item in optionsLevel" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="gl_top_fal">
                    <div class="title">创建时间</div>
                    <el-date-picker value-format="yyyy-MM-dd" format="yyyy-MM-dd" v-model="input" type="datetimerange"
                        :picker-options="pickerOptions" range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期"
                        align="right" @change="onClickPicker">
                    </el-date-picker>
                </div>
            </div>
            <div class="gl_top_buttons">
                <div @click="onClickScreen" class="screen">筛选</div>
                <div @click="onClickReset(true)" class="resetting">重置</div>
            </div>
        </div>
        <!-- 内容部分 -->
        <div class="gl_main_view">
            <!-- <div class="gl_button" @click="onClickUser('add')">添加团队</div> -->
            <el-table :data="tableData" style="width: 100%" :cell-style="{ 'text-align': 'center' }"
                :header-cell-style="{ background: '#B5DFFF', color: '#333333', 'text-align': 'center' }">
                <el-table-column prop="id" label="ID" width="80">
                </el-table-column>
                <el-table-column prop="name" label="团队名称">
                </el-table-column>
                <el-table-column prop="levelName" label="等级" width="50">
                </el-table-column>
                <el-table-column prop="startDate" label="开始日期">
                </el-table-column>
                <el-table-column prop="endDate" label="结束日期">
                </el-table-column>
                <el-table-column label="状态" width="80">
                    <template slot-scope="scope">
                        <div v-if="scope.row.status == 1">待生效</div>
                        <div v-if="scope.row.status == 2">生效中</div>
                        <div v-if="scope.row.status == 3">已过期</div>
                        <div v-if="scope.row.status == 4">已撤销</div>
                    </template>
                </el-table-column>
                <el-table-column label="状态" width="80">
                    <template slot-scope="scope">
                        <div v-if="scope.row.type == 1">充值赠送</div>
                        <div v-if="scope.row.type == 2">后台赠送</div>
                    </template>
                </el-table-column>

                <el-table-column prop="createdTime" label="创建时间">
                </el-table-column>
                <el-table-column prop="updatedTime" label="最后编辑时间">
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.status == 1" @click="handleClickEdit(scope.row, 1)"
                            type="text">撤销</el-button>
                        <el-button v-if="scope.row.status == 2" @click="handleClickEdit(scope.row, 2)"
                            type="text">设为失效</el-button>
                        <div v-if="scope.row.status == 3 || scope.row.status == 4">-</div>
                    </template>
                </el-table-column>
            </el-table>
            <div class="gl_pagination">
                <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                    :current-page="pages.pageNumber" :page-sizes="[10, 20, 30, 50]" :page-size="pages.pageSize"
                    layout="total, sizes, prev, pager, next, jumper" :total="total">
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            input: [],
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 1);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 1);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        end.setTime(end.getTime() + 3600 * 1000 * 24 * 1);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            optionsLevel: [],
            currentPage4: 4,
            tableData: [],
            total: 0,
            pages: {
                name: "",
                levelId: "",
                startTime: "",
                endTime: "",
                pageNumber: 1,
                pageSize: 10,
            },
        }
    },
    created() {
        this.getList(true);
        this.getLevelList();
    },
    mouted() {

    },
    methods: {
        // 点击时间选择
        onClickPicker(event) {
            console.log(event)
            if (event == null) {
                this.pages.startTime = '';
                this.pages.endTime = '';
            } else {
                this.pages.startTime = event[0];
                this.pages.endTime = event[1];
            }
        },
        // 分页
        handleSizeChange(val) {
            this.pages.pageSize = val;
            this.getList(true);
        },
        handleCurrentChange(val) {
            this.pages.pageNumber = val;
            this.getList(true);
        },
        // 点击重置顶部form
        onClickReset(type) {
            this.pages = {
                name: "",
                levelId: "",
                startTime: "",
                endTime: "",
                pageNumber: 1,
                pageSize: 10,
            }
            this.input = [];
            this.getList(type);
        },
        // 点击筛选
        onClickScreen() {
            this.pages.pageNumber = 1;
            this.pages.pageSize = 10;
            this.getList(true);
        },
        // 点击撤销
        handleClickEdit(data, num) {
            let tex = num == 1 ? '是否撤销本次赠送的会员记录？' : '是否将此会员等级设为失效？';
            this.$confirm(tex, '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                var { data: res } = await this.$http({
                    url: "/member/cancelMemberLevelRecord",
                    method: "post",
                    data: { id: data.id }
                });
                if (res.resultStates != 0) return this.$message.error(res.message)
                this.$message.success("撤销成功")
                this.getList(false)
            }).catch(() => { });
        },
        // 获取列表
        async getList(type) {
            const { data: res } = await this.$http({
                url: "/member/getMemberLevelRecordList",
                method: "post",
                data: this.pages
            });
            this.total = res.content.count
            this.tableData = res.content.items
            if (type) {
                this.$message.success("查询成功")
            }
        },
        // 会员接口
        async getLevelList() {
            const { data: res } = await this.$http({
                url: "/member/getLevelList",
                method: "post",
                data: {}
            });
            let optionsLevel = [];
            for (var a = 0; a < res.content.length; a++) {
                optionsLevel.push({
                    value: res.content[a].id,
                    label: res.content[a].name
                })
            }
            this.optionsLevel = optionsLevel;
        },
    },
}
</script>
<style scoped>
.gl_top_view {
    width: 100%;
    background: rgba(255, 255, 255, 0.39);
    box-shadow: 0px 0px 10px #BFDFF7;
    opacity: 1;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 30px 20px 20px;
}

.gl_top_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gl_top_fal {
    /* width: 284px; */
    width: 30%;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.gl_top_fal>.title {
    min-width: 64px;
    max-width: 64px;
    margin-right: 10px;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 400;
    color: #666666;
    text-align: right;
}

.gl_top_fal .input-with {
    width: 100%;
    font-size: 16px !important;
    font-family: PingFang SC;
    font-weight: 400;
    opacity: 1;
}

.gl_top_buttons {
    width: 100%;
    height: 34px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 20px;
}

.gl_top_buttons>div {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 115px;
    height: 34px;
    opacity: 1;
    border-radius: 6px;
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
}

.gl_top_buttons>.screen {
    background: rgba(0, 131, 227);
    /* border: 1px solid #1883E3; */
    color: #FFFFFF;
    cursor: pointer;

}

.gl_top_buttons>.resetting {
    border: 1px solid #1883E3;
    color: #1883E3;
    margin-left: 20px;
    cursor: pointer;

}

.gl_main_view {
    width: 100%;
    background: rgba(255, 255, 255);
    opacity: 1;
    border-radius: 10px;
    margin-top: 20px;
    box-sizing: border-box;
    padding: 20px;
}

.gl_button {
    width: 100px;
    height: 34px;
    background: rgba(0, 131, 227);
    opacity: 1;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: 500;
    line-height: 22px;
    color: #FFFFFF;
    margin-bottom: 20px;
}

.gl_pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}
</style>
<style>
.input_comm input {
    width: 100%;
    font-size: 16px !important;
    font-family: PingFang SC;
    font-weight: 400;
    opacity: 1;
}
</style>